const idPortalApi = "02c17067bd86331deb3806b75d5c7768c53578a9";

module.exports = {
  idPortalApi: `${idPortalApi}`,
  // Produção
  urlApi: `https://api-portais-workcenter.com.br/${idPortalApi}`,
  // Local
  // urlApi: `http://localhost:8004/${idPortalApi}`,

  //config botões e imagens
  urlADM: `https://administracaopublica.com.br`,

  dnsPortal: "matoes.ma.gov.br",
  NomePortal: "Matões",
  ufPortal: "MA",
  NomeTipoPortal: "Prefeitura Municipal", //Prefeitura ou Câmara
  TipoPortal: 3, //3 = Prefeitura 4=Câmara

  //Variável em teste (newsViewRoute)
  newsViewRoute: false, //Essa opção informa se a rota de visualização de uma única noticia está disponível (Raposa gera as noticias pelo lado do servidor com nextjs)

  countNewsHome: 9, //quantidade de noticias na home

  RoutesDaily: true, //Deixa ativado as rotas para a pagina de diario

  link: "http://www.transparenciadministrativa.com.br", //Onde o token abaixo ser[a utilizado]
  linkArquivos: "http://www.transparenciadministrativa.com.br/portalimagem", //Onde o token abaixo ser[a utilizado]
  tokenLink: `${idPortalApi}`, //Token para acessar o WWW.transparência

  urlEmDeploy: "https://matoes.ma.gov.br/", //usada para link de compartilhamento
  imagemURL: "https://cdn.administracaopublica.com.br/complementares/", //link para a imagem
  //imagemURL: 'http://localhost:8004/uploads/', //link para a imagem

  corIcones: "#000080",

  link_youtube: `https://www.youtube.com/channel/UCmNL1RjhuVRNQ8AU9NwU6CQ`,
  link_tiktok: `#`,
  link_twitter: `#`,
  link_threads: `#`,
  link_localize: `https://maps.app.goo.gl/H1kNB6TPwtvPjYEN6`,
  //Mapa da pagina Municipio/Mapa
  link_mapa: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29308.496209182875!2d-43.21061962888312!3d-5.52741016851029!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x78f76685910025f%3A0xb11f812ce55048e1!2sMat%C3%B5es%2C%20State%20of%20Maranh%C3%A3o%2C%2065645-000!5e0!3m2!1sen!2sbr!4v1741802058704!5m2!1sen!2sbr`,
};
